import React, { Dispatch, SetStateAction } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

const MAX_GUESSES = 6;

type ColorSetter = Dispatch<SetStateAction<number>>

type Guess = {
  red: number;
  green: number;
  blue: number;
};

type GuessResultProps = Guess & {
  score: number;
};

const scoreGuess = (guess: Guess, answer: Guess) => {
  return (
    ((guess.red - answer.red) ** 2 +
      (guess.blue - answer.blue) ** 2 +
      (guess.green - answer.green) ** 2) ** (0.5)
  );
};

const getContrastColor = (background: Guess) => {
  // based on https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  const intensity = background.red * 0.299 + background.blue * 0.114 + background.green * 0.587

  if (intensity > 149) {
    return 'black'
  }
  return 'white'
}

const GuessBar = ({ guess, borderColor }: { guess?: GuessResultProps, borderColor: 'white' | 'black' }) => {
  if (guess === undefined) {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        sx={{ width: 50, height: 50, color: borderColor, border: 2, borderRadius: 2 }}
      />
    );
  }
  return (
    <Tooltip title={`R: ${guess.red}, G: ${guess.green}, B: ${guess.blue}`}>
      <Box
        width={50}
        height={50}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: `rgb(${guess.red}, ${guess.green}, ${guess.blue})`,
          color: borderColor,
          border: 2,
          borderRadius: 2
        }}
      >
        <Typography sx={{ color: getContrastColor(guess) }}>{Math.round(guess.score * 10) / 10}</Typography>
      </Box>
    </Tooltip>
  );
};

const ColorSliders = ({ redSetter, greenSetter, blueSetter, fontColor }: {
  redSetter: ColorSetter,
  greenSetter: ColorSetter,
  blueSetter: ColorSetter
  fontColor: 'white' | 'black'
}) => (
  <Grid container alignItems="center">
    <ColorSlider fontColor={fontColor} colorName="Red" colorSetter={redSetter} />
    <ColorSlider fontColor={fontColor} colorName="Green" colorSetter={greenSetter} />
    <ColorSlider fontColor={fontColor} colorName="Blue" colorSetter={blueSetter} />
  </Grid>
)

const ColorSlider = ({
  colorName,
  colorSetter,
  fontColor
}: {
  colorName: string
  colorSetter: ColorSetter
  fontColor: 'white' | 'black'
}) => (
  <>
    <Grid item xs={2} justifyContent="flex-end">
      <Typography sx={{ color: fontColor }}>{colorName}</Typography>
    </Grid>
    <Grid item xs={10}>
      <Slider
        valueLabelDisplay="auto"
        sx={{ color: colorName.toLowerCase() }}
        aria-label={colorName}
        min={0}
        max={255}
        onChange={(_event, value) => colorSetter(value as number)}
      />
    </Grid>
  </>

);

const GuessList = ({ guessList, answer }: { guessList: GuessResultProps[], answer: Guess }) => {
  const numGuesses = guessList.length
  const borderColor = getContrastColor(answer)

  return <Stack direction="row" justifyContent="space-evenly">
    {guessList.map((guess, idx) => (
      <GuessBar
        borderColor={borderColor}
        key={idx}
        guess={{ ...guess, score: scoreGuess(guess, answer) }}
      />
    ))}
    {Array.from(Array(MAX_GUESSES - numGuesses)).map((_, idx) => (
      <GuessBar borderColor={borderColor} key={idx + numGuesses} />
    ))}
  </Stack>
}

function App() {
  const [guessList, setGuessList] = React.useState<GuessResultProps[]>([]);
  const [answer, setAnswer] = React.useState<Guess>({
    red: 0,
    blue: 0,
    green: 0,
  });
  const [red, redSetter] = React.useState(0);
  const [green, greenSetter] = React.useState(0);
  const [blue, blueSetter] = React.useState(0);

  const newGame = () => {
    setAnswer({
      red: Math.floor(Math.random() * 256),
      green: Math.floor(Math.random() * 256),
      blue: Math.floor(Math.random() * 256),
    });
    setGuessList([])
  };

  const rgbAnswer = `rgb(${answer.red}, ${answer.green}, ${answer.blue})`

  React.useEffect(() => {
    newGame()
  }, []);

  const addToGuessList = (guess: Guess) => {
    const guessResult = { ...guess, score: scoreGuess(guess, answer) };
    setGuessList([...guessList, guessResult]);
  };

  return (
    <Box sx={{ height: "100vh", backgroundColor: rgbAnswer }}>
      <Stack component={Box} spacing={5} sx={{ width: { sm: "60%", xs: "90%" }, mx: 'auto', pt: 10 }}>
        <Typography variant="h3" component="h1" sx={{ margin: 'auto', color: getContrastColor(answer) }} >Guess The Color</Typography>
        <GuessList guessList={guessList} answer={answer} />
        {guessList.length < MAX_GUESSES ? (
          <Stack alignItems="center">

            <ColorSliders fontColor={getContrastColor(answer)} redSetter={redSetter} greenSetter={greenSetter} blueSetter={blueSetter} />

            <Button
              variant="contained"
              sx={{ mt: 2, width: { xs: '100%', md: 150 } }}
              onClick={() => addToGuessList({ red, green, blue })}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 2, width: { xs: '100%', md: 150 } }}
              onClick={() => newGame()}
            >
              New Game
            </Button>
          </Stack>
        ) : (
          <p>
            Good Try! Answer: Red: {answer.red}, Green: {answer.green}, Blue:{" "}
            {answer.blue}
          </p>
        )}
      </Stack>
    </Box >
  );
}

export default App;
